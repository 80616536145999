import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import cookie from "react-cookies";
import dynamic from "next/dynamic";
// component

const Footer = dynamic(() => import("./footer"));
import { loginPageState, userIsLoggedState } from "../../redux/cart.slice";
// Images

// icons
import Close from "@material-ui/icons/Close";
import Home from "@material-ui/icons/Home";
import AccountCircle from "@material-ui/icons/AccountCircle";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import HistoryIcon from "@material-ui/icons/History";
import UndoIcon from "@material-ui/icons/Undo";
import ChevronDown from "@material-ui/icons/KeyboardArrowDownOutlined";
import StarIcon from "@material-ui/icons/Star";

export default function MobileDrawer(props) {
  const [isLogged, setIsLogged] = useState(false);
  const [showMyAccount, setMyAccount] = useState(false);
  const [showRewardButton, setRewardButton] = useState(false);
  const router = useRouter();
  const { query } = useRouter();
  const UserIsLogged = useSelector((state) => state.cart.userIsLogged);
  const dispatch = useDispatch();
  const RouterDefine = router.pathname.split("/");
  useEffect(() => {
    const login = localStorage.getItem("guest_id");
    console.log(login, "logib");
    if (login !== null && UserIsLogged === true) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, [UserIsLogged]);

  const mobileDwawerclick = () => {
    // document.body.classList.remove("scroll-disable-body");
    return props.onClickback(false);
  };

  useEffect(() => {
    if (RouterDefine[1] === "") {
      setRewardButton(false);
    } else if (RouterDefine[1] === "locationDetails") {
      setRewardButton(false);
    } else if (RouterDefine[1] === "search") {
      setRewardButton(false);
    } else {
      setRewardButton(true);
    }
  }, [RouterDefine]);

  const onClickReward = () => {
    const restaurantId = query.restaurantId;
    router.push(`/rewards/${restaurantId}`, undefined, { shallow: true });
    return props.onClickback(false);
  };

  const onClickStartOrder = () => {
    router.push("/");
    return props.onClickback(false);
  };

  const onClickSignUp = () => {
    router.push("/customer/signIn");
    return props.onClickback(false);
  };

  const onClickRecentOrder = () => {
    console.log("hello");
    setMyAccount(false);
    router.push("/recent-orders");
    return props.onClickback(false);
  };

  const onClickFavoriteOrders = () => {
    console.log("hello");
    setMyAccount(false);
    router.push("/favorite-order");
    return props.onClickback(false);
  };

  const onClickAccountSettings = () => {
    console.log("hello");
    setMyAccount(false);
    router.push("/view-account");
    return props.onClickback(false);
  };

  const onClickYourAccount = () => {
    console.log("hello");
    setMyAccount(!showMyAccount);
    // return props.onClickback(false);
  };

  const logout = (e) => {
    e.preventDefault();
    dispatch(userIsLoggedState(false));
    dispatch(loginPageState(false));
    localStorage.removeItem("guest_id");
    localStorage.removeItem("spendgo_id");
    cookie.remove("guest_id");
    cookie.remove("spendgo_id");
    setIsLogged(false);
    window.location.href = "/";
    return props.onClickback(false);
    // router.push("/", undefined, { shallow: true });
  };

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="p-2 bg-darkGreen">
          <button
            onClick={mobileDwawerclick}
            className="block lg:hidden bg-darkGreen w-10 h-10  shadow-none hover:shadow-3xl transition duration-300"
          >
            <div className="">
              <Close style={{ color: "#ffffff", fontSize: "36px" }} />
            </div>
          </button>
        </div>
        <div className="grow flex flex-col relative overflow-auto">
          <div className="pt-8 pb-10">
            <div className="px-4 pb-4">
              <h2 className="text-28xl text-black-1 font-semibold">
                Hi there!
              </h2>
            </div>
            <ul>
              <li className="px-4 py-2">
                <button onClick={onClickStartOrder}>
                  <a className="flex items-center text-17xl text-black-3">
                    <span className="mr-3">
                      <Home style={{ color: "#414142", fontSize: "26px" }} />
                    </span>
                    <span>Start a New Order</span>
                  </a>
                </button>
              </li>
              {/* <li className="px-4 py-2">
                {isLogged && showRewardButton && (
                  <button
                    onClick={onClickReward}
                    // className="lg:block mr-4  h-10 py-2 px-1 bg-darkGreen  shadow-none hover:shadow-3xl transition duration-300 rounded-3"
                  >
                    <a className="flex items-center text-17xl text-black-3">
                      <span className="mr-3">
                        <StarIcon
                          style={{
                            color: "#414142",
                            fontSize: "26px",
                          }}
                        />
                      </span>
                      <span>View Rewards</span>
                    </a>
                  </button>
                )}
              </li> */}

              {isLogged ? (
                <div className="relative">
                  <button
                    onClick={onClickYourAccount}
                    className={`w-full px-4 py-2 flex justify-between sr-account-btn items-center text-17xl text-black-3 ${
                      showMyAccount ? "open" : ""
                    }`}
                  >
                    <div>
                      <span className="mr-3">
                        <AccountCircle
                          style={{ color: "#414142", fontSize: "26px" }}
                        />
                      </span>
                      <span className="">Your Account</span>
                    </div>
                    <span className="sr-account-arrow">
                      <ChevronDown
                        className="my-px"
                        style={{
                          color: "#111111",
                          fontSize: "24px",
                        }}
                      />
                    </span>
                  </button>
                  {/* My account Drawer */}

                  <div
                    className={`sr-reward-nav lg:absolute top-52 right-0 bg-white lg:border sm:border-gray-9 lg:shadow-4xl  ${
                      showMyAccount ? "open" : ""
                    }`}
                  >
                    <ul className="min-w-250 bg-neutral-100">
                      <li>
                        <button
                          className="px-4 w-full text-left py-3 lg:px-4 text-sm md:text-base text-black-1 hover:bg-neutral-100 "
                          onClick={onClickRecentOrder}
                        >
                          <HistoryIcon
                            style={{
                              color: "#414141",
                              fontSize: "24px",
                              margin: "0px 4px",
                            }}
                          />
                          <span className="ml-1 lg:ml-3">Recent Orders</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="px-4 w-full text-left py-3 lg:px-4 text-sm md:text-base text-black-1 hover:bg-neutral-100 "
                          onClick={onClickFavoriteOrders}
                        >
                          <FavoriteBorder
                            style={{
                              color: "#414141",
                              fontSize: "24px",
                              margin: "0px 4px",
                            }}
                          />
                          <span className="ml-1 lg:ml-3"> Favorite Orders</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="px-4 w-full text-left py-3 lg:px-4 text-sm md:text-base text-black-1 hover:bg-neutral-100 "
                          onClick={onClickAccountSettings}
                        >
                          <PersonOutlineOutlinedIcon
                            style={{
                              color: "#414141",
                              fontSize: "24px",
                              margin: "0px 4px",
                            }}
                          />
                          <span className="ml-1 lg:ml-3">
                            {" "}
                            Account Settings
                          </span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="px-4 w-full text-left py-3 lg:px-4 text-sm md:text-base text-black-1 hover:bg-neutral-100 "
                          onClick={logout}
                        >
                          <UndoIcon
                            style={{
                              color: "#414141",
                              fontSize: "24px",
                              margin: "0px 4px",
                            }}
                          />
                          <span className="ml-1 lg:ml-3">Sign Out</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <li className="px-4 py-2">
                  <button onClick={onClickSignUp}>
                    <a className="flex items-center text-17xl text-black-3">
                      <span className="mr-3">
                        <AccountCircle
                          style={{ color: "#414142", fontSize: "26px" }}
                        />
                      </span>
                      <span>Sign In / Create Account</span>
                    </a>
                  </button>
                </li>
              )}
            </ul>
          </div>
          {/* footer */}
          <Footer />
        </div>
      </div>
    </>
  );
}
